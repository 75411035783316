import React from 'react';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import GraphLineIcon from '@atlaskit/icon/core/migration/chart-trend--graph-line';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';

import * as S from './styled';

const i18n = defineMessages({
	viewers: {
		id: 'confluence-analytics-byline.user-views.message',
		defaultMessage:
			'{count, plural, =0 {Analytics} one {1 person viewed} other {{count} people viewed}}',
		description: 'Analytics byline text to represent the count of unique viewers for the page.',
	},
	abbreviatedViewers: {
		id: 'confluence-analytics-byline.abbreviated-user-views.message',
		defaultMessage: '{count, plural, =0 {Views} one {1} other {{count}}}',
		description:
			'Abbreviated analytics byline text to represent the count of unique viewers for the page.',
	},
	pageViews: {
		id: 'confluence-analytics-byline.page-views.message',
		defaultMessage: 'Page views',
		description:
			'Text to represent the label text for the watch icon showing the number of page views.',
	},
});

export type AnalyticsBylineProps = {
	count: number;
	isBylineComponent?: boolean;
	onClick?: (e?: React.MouseEvent<Element>) => void;
	href?: string;
	testId?: string;
	highlightPageViews?: boolean;
};

const handleOnClick =
	(onClick?: (e?: React.MouseEvent<Element>) => void) =>
	(e?: React.MouseEvent<Element>): void => {
		if (onClick) {
			return onClick(e);
		}
		return undefined;
	};

const formatAbbreviatedNumber = (locale: string, count: number): string => {
	try {
		const numberFormatter = Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
		});
		return numberFormatter.format(count).toLocaleLowerCase();
	} catch (e) {
		return String(count);
	}
};

const AnalyticsByline = ({
	isBylineComponent,
	onClick,
	href,
	count,
	testId,
	highlightPageViews,
}: AnalyticsBylineProps) => {
	const intl = useIntl();
	const value = formatAbbreviatedNumber(intl.locale, count);
	const AnalyticsIconComponent = highlightPageViews ? WatchIcon : GraphLineIcon;
	const analyticsIconLabel = highlightPageViews ? intl.formatMessage(i18n.pageViews) : '';
	const analyticsBylineMessage = highlightPageViews ? i18n.abbreviatedViewers : i18n.viewers;

	return (
		<S.AnalyticsBylineWrapper
			isBylineComponent={isBylineComponent}
			highlightPageViews={highlightPageViews}
			data-testid={testId}
		>
			<Button
				href={href}
				onClick={handleOnClick(onClick)}
				appearance="subtle-link"
				spacing="none"
				// TODO: (from codemod) Buttons with "component", "css" or "style" prop can't be automatically migrated with codemods. Please migrate it manually.
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ fontWeight: 'unset' }}
			>
				<S.AnalyticsIconWrapper>
					<AnalyticsIconComponent
						label={analyticsIconLabel}
						color="currentColor"
						LEGACY_size="small"
					/>
				</S.AnalyticsIconWrapper>
				<FormattedMessage {...analyticsBylineMessage} values={{ count: value }} />
			</Button>
		</S.AnalyticsBylineWrapper>
	);
};

export default AnalyticsByline;
