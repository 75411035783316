/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type HTMLProps } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const analyticsIconWrapperStyles = css({
	marginRight: token('space.050', '4px'),
	verticalAlign: 'middle',
});

export const AnalyticsIconWrapper = (props: HTMLProps<HTMLSpanElement>) => (
	<span {...props} css={[analyticsIconWrapperStyles]} />
);

const analyticsBylineWrapperStyles = css({
	display: 'inline-block',
	color: token('color.text.subtle', N200),
	fontWeight: 400,
	marginRight: token('space.100', '8px'),
	paddingLeft: token('space.050', '4px'),

	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:before': {
			/* for IE <= 11 */
			textDecoration: 'none',
		},
	},

	'&:focus': {
		textDecoration: 'underline',
	},
});

const isBylineComponentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '"•"',
		display: 'inline-block',
		color: token('color.text.subtle', N200),
		paddingRight: token('space.100', '8px'),
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

interface AnalyticsBylineWrapperProps extends HTMLProps<HTMLSpanElement> {
	isBylineComponent?: boolean;
	highlightPageViews?: boolean;
}

export const AnalyticsBylineWrapper = (props: AnalyticsBylineWrapperProps) => {
	const { isBylineComponent, highlightPageViews, children, ...spanProps } = props;

	return (
		<span
			{...spanProps}
			css={[
				analyticsBylineWrapperStyles,
				isBylineComponent && !highlightPageViews ? isBylineComponentStyles : null,
			]}
		>
			{children}
		</span>
	);
};
